import React, {
  useMemo,
  useCallback,
  useState,
  useEffect,
  useRef,
} from "react";

// UX
import CardSubAllergyHistory from "react-lib/apps/HISV3/TPD/CardSubAllergyHistory";
import CardAdverseReactionNote from "react-lib/apps/HISV3/TPD/CardAdverseReactionNote";
import { ADR_TYPES } from "react-lib/apps/HISV3/TPD/sequence/Allergy";
import { Button, Checkbox, Icon, Modal } from "semantic-ui-react";
import { useIntl } from "react-intl";

// Types
type CardAllergyProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: any;
  AllergySequence: any;
  // CommonInterface
  errorMessage: any;
  successMessage: any;
  masterOptions: any;
  searchedItemList: any;
  DJANGO?: any;
  selectedEmr?: any;
  patientId?: any;
  restart?: boolean;
  // noEmr?: boolean;
  onDataUpdated?: () => void;
};

// Const
const CARD_ALLERGY = "CardAllergy";

const CardAllergy = (props: CardAllergyProps) => {
  const intl = useIntl();
  const [openADRDetail, setOpenADRDetail] = useState<boolean>(false);
  const [modAllergyHistory, setModAllergyHistory] = useState<any>("");

  // Ref
  const newARDRef = useRef<HTMLDivElement>(null);

  // if open encounter without doctor
  useEffect(() => {
    props.runSequence({
      sequence: "Allergy",
      restart: props.restart,
      noEmr: !props.selectedEmr,
      // cudent
      patient: props.patientId,
    });

    return () => {
      props.setProp("AllergySequence.adrSelected", null);
    };
  }, [props.patientId]);

  // * Open ADRDetail
  useEffect(() => {
    const adrSelected = props.AllergySequence?.adrSelected;

    if (adrSelected !== null && adrSelected !== undefined) {
      setOpenADRDetail(true);
    } else if (adrSelected === null || adrSelected === undefined) {
      setOpenADRDetail(false);
    }
  }, [props.AllergySequence?.adrSelected]);

  // * Scroll to bottom
  useEffect(() => {
    if (openADRDetail) {
      if (newARDRef?.current?.scrollIntoView) {
        newARDRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }
  }, [openADRDetail]);

  // Callback
  const filterADR = useCallback((adrList: any[], type: any) => {
    return adrList.filter((item) => item.type_name_name === type);
  }, []);

  // Memo
  const groupADR = useMemo(() => {
    const adrList: any[] = props.AllergySequence?.adrList || [];

    return {
      drug: filterADR(adrList, ADR_TYPES.DRUG),
      food: filterADR(adrList, ADR_TYPES.FOOD),
      other: filterADR(adrList, ADR_TYPES.OTHER),
    };
  }, [props.AllergySequence?.adrList]);

  // Handler
  const handleCancelNewADRNote = () => {
    props.onEvent({
      message: "RunSequence",
      params: { sequence: "Allergy", action: "clear" },
    });
    setOpenADRDetail(false);
  };

  const handleSaveNewADRNote = () => {
    if (props?.AllergySequence?.adrSelected) {
      props.onEvent({
        message: "RunSequence",
        params: {
          sequence: "Allergy",
          action: "save",
          card: CARD_ALLERGY,
          onDataUpdated: props.onDataUpdated,
        },
      });
    }
  };

  const handleSaveCommonADRNote = () => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "Allergy",
        action: "saveIngredientData",
        card: CARD_ALLERGY,
        onDataUpdated: props.onDataUpdated,
      },
    });
  };

  const handleOpenModAllergyHistory = (type: any) => {
    setModAllergyHistory(type);
  };

  const handleCloseModAllergyHistory = () => {
    setModAllergyHistory("");
  };

  const handleAddADR = () => {
    props.runSequence({
      sequence: "Allergy",
      action: "newNote",
    });
  };

  const handleShowInactive = (e: any, { checked }: any) => {
    props.setProp("AllergySequence.showInactive", checked);

    props.runSequence({
      sequence: "Allergy",
      action: "inactive",
      checked: checked,
    });
  };

  console.log("CardAllergy", props, groupADR);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "0 20px",
        }}
      >
        <div style={{ fontSize: "1.25rem", fontWeight: "bold" }}>{intl.formatMessage({ id: "ประวัติการเกิดอาการไม่พึงประสงค์" })}</div>
        <div style={{ flex: 1 }}></div>
        <Checkbox
          id="CardAllergy-Checkbox-ShowInactive"
          checked={props.AllergySequence?.showInactive}
          label={intl.formatMessage({ id: "แสดงประวัติ Inactived" })}
          style={{ marginRight: "1.25rem" }}
          onChange={handleShowInactive}
        ></Checkbox>
        <Button id="CardAllergy-Button-AddAllergy" color="blue" icon={true} name="New" onClick={handleAddADR} style={{ fontWeight: "bold", margin: "1.25rem 0" }}>
          เพิ่มประวัติ ADR
          <Icon name="plus"></Icon>
        </Button>
      </div>

      {["drug", "food", "other"].map((key) => {
        return (
          <CardSubAllergyHistory
            key={"allergy" + key}
            onEvent={props.onEvent}
            setProp={props.setProp}
            // seq
            runSequence={props.runSequence}
            AllergySequence={props.AllergySequence}
            type={key as any}
            ardList={(groupADR as any)[key]}
            DJANGO={props.DJANGO}
            // options
            masterOptions={props.masterOptions}
            // CommonInterface
            errorMessage={props.errorMessage}
            successMessage={props.successMessage}
            // callback
            onAllergyHistory={handleOpenModAllergyHistory}
            onDataUpdated={props.onDataUpdated}
            languageUX={props.languageUX}
          />
        );
      })}

      {openADRDetail && (
        <div ref={newARDRef}>
          <CardAdverseReactionNote
            rolePharma={props.DJANGO?.user?.role_types.includes("PHARMACIST")}
            searchedItemList={props.searchedItemList}
            masterOptions={props.masterOptions}
            onCancelNewADRNote={handleCancelNewADRNote}
            onSaveNewADRNote={handleSaveNewADRNote}
            onSaveCommonADRNote={handleSaveCommonADRNote}
            AllergySequence={props.AllergySequence}
            onEvent={props.onEvent}
            setProp={props.setProp}
            adrSelected={props.AllergySequence?.adrSelected}
            onEdit={true}
            languageUX={props.languageUX}
          />
        </div>
      )}

      <Modal open={!!modAllergyHistory} closeOnDimmerClick={true} onClose={handleCloseModAllergyHistory}>
        <CardSubAllergyHistory
          // seq
          runSequence={props.runSequence}
          AllergySequence={props.AllergySequence}
          type={modAllergyHistory}
          ardList={(groupADR as any)[modAllergyHistory]}
          DJANGO={props.DJANGO}
          onShowInactive={handleShowInactive}
          // options
          masterOptions={props.masterOptions}
          // config
          modal={true}
          languageUX={props.languageUX}
        />
      </Modal>
    </div>
  );
};
export default React.memo(CardAllergy);
