import React, { CSSProperties, ReactElement, SyntheticEvent, useCallback, useMemo } from "react";

import SnackMessage from "react-lib/apps/common/SnackMessage";

import LoadingIcon from "../HCU/LoadingIcon";

import CONFIG from "config/config";

// Types
type ButtonLockDrugOrderProps<T extends boolean> = ValueProps<T> & {
  readOnly?: T;
  status: StatusType;
};

type FullValueProps = {
  onEvent: (e: any) => void;
  setProp: (key: string, value: any, callback?: () => void) => void;

  orderId: number;
  style?: CSSProperties;
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  // callback
  onSuccess?: (status: string) => void;
};

type ValueProps<T extends boolean> = T extends true ? Partial<FullValueProps> : FullValueProps;
type StatusType = keyof typeof STATUS;

type Styles = Record<"dimmer" | "icon", CSSProperties>;

// Constants
const IMAGES = {
  lock: "/static/images/drugOrder/drug-order-lock.png",
  unlock: "/static/images/drugOrder/drug-order-unlock.png",
};

const styles: Styles = {
  dimmer: { margin: 0 },
  icon: {
    opacity: 1,
    width: "13px",
  },
};

const STATUS = {
  LOCKED: "LOCKED",
  UNLOCKED: "UNLOCKED",
} as const;

const ACTIONS = {
  LOCK: "LOCK",
  UNLOCK: "UNLOCK",
} as const;

const ACTION_MAPPING = {
  [STATUS.LOCKED]: ACTIONS.UNLOCK,
  [STATUS.UNLOCKED]: ACTIONS.LOCK,
};

const BUTTON_ACTIONS = {
  save: "SAVE",
};

const BUTTON_LOCK_DRUG_ORDER = "ButtonLockDrugOrder";

const ACTION_SAVE = `${BUTTON_LOCK_DRUG_ORDER}_${BUTTON_ACTIONS.save}`;

const ButtonLockDrugOrder = (props: ButtonLockDrugOrderProps<boolean>) => {
  const iconStyle = useMemo(() => ({ ...styles.icon, ...props.style }), [props.style]);

  const handleCloseModError = useCallback(() => {
    props.setProp?.(`errorMessage.${ACTION_SAVE}_${props.orderId}`, null);
  }, [props.orderId]);

  const handleSave = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();

      props.onEvent?.({
        message: "UpdateDrugOrderLocker",
        params: {
          action: ACTION_MAPPING[props.status],
          id: props.orderId,
          btnAction: `${ACTION_SAVE}_${props.orderId}`,
          onSuccess: (status: string) => {
            props.onSuccess?.(status);
          },
        },
      });
    },
    [props.orderId, props.status]
  );

  return CONFIG.ENABLE_DRUG_ORDER_LOCKER ? (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage?.[`${ACTION_SAVE}_${props.orderId}`]}
        success={null}
        onClose={handleCloseModError}
      />

      <LoadingIcon
        dimmerStyle={styles.dimmer}
        disabled={props.readOnly}
        img={props.status === "LOCKED" ? IMAGES.lock : IMAGES.unlock}
        loading={props.buttonLoadCheck?.[`${ACTION_SAVE}_${props.orderId}`] === "LOADING"}
        style={iconStyle}
        onClick={handleSave}
      />
    </div>
  ) : null;
};

ButtonLockDrugOrder.displayName = "ButtonLockDrugOrder";

export default React.memo(ButtonLockDrugOrder) as <T extends boolean>(
  props: ButtonLockDrugOrderProps<T>
) => ReactElement;
